<template>
  <div
    v-editable="blok"
    :class="`sb-spacing`"
    :style="{
      height: spacingHeight,
    }"
  />
</template>

<script lang="ts" setup>
import type { SbSpacingStoryblok } from '@/types'

interface Props {
  blok: SbSpacingStoryblok
}
const props = defineProps<Props>()
const spacingHeight = computed(() => `${props.blok?.size || 0}px`)
</script>
